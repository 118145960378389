import { createAction } from 'redux-actions';
import {
  FETCH_CALENDAR_LINK,
  FETCH_CALENDAR_LINK_SUCCESS,
  FETCH_CALENDAR_LINK_FAILURE,
} from 'modules/calendar/actionTypes';

export const fetchCalendarLink = createAction(FETCH_CALENDAR_LINK);

export interface FetchCalendarLinkSuccessPayload {
  icalendarUrl: string;
}
export const fetchCalendarLinkSuccess = createAction<
  FetchCalendarLinkSuccessPayload
>(FETCH_CALENDAR_LINK_SUCCESS);
export const fetchCalendarLinkFailure = createAction(
  FETCH_CALENDAR_LINK_FAILURE
);
