import { combineReducers } from 'redux';
import { Action, handleActions } from 'redux-actions';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';
import { FetchStatus } from 'types/types';
import {
  FETCH_CALENDAR_LINK,
  FETCH_CALENDAR_LINK_FAILURE,
  FETCH_CALENDAR_LINK_SUCCESS,
} from 'modules/calendar/actionTypes';
import { FetchCalendarLinkSuccessPayload } from 'modules/calendar/actions';

export interface CalendarState {
  calendarUrl: string;
  fetchStatus: FetchStatus;
}

const calendarUrl = handleActions(
  {
    [FETCH_CALENDAR_LINK]: () => '',
    [FETCH_CALENDAR_LINK_SUCCESS]: (
      state,
      action: Action<FetchCalendarLinkSuccessPayload>
    ) => action.payload.icalendarUrl,
    [FETCH_CALENDAR_LINK_FAILURE]: () => '',
  },
  ''
);

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [FETCH_CALENDAR_LINK],
  successActions: [FETCH_CALENDAR_LINK_SUCCESS],
  failureActions: [FETCH_CALENDAR_LINK_FAILURE],
});

export default combineReducers<CalendarState>({
  calendarUrl,
  fetchStatus,
});
