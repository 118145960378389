export const LOGIN_PATH = '/login';
export const LOGIN_GOOGLE_REDIRECT_PATH = '/signin-google';
export const BOOKING_PATH = '/booking';
export const SCHEDULE_PATH = '/schedule';
export const MESSAGES_PATH = '/messages';
export const MORE_PATH = '/more';
export const USER_DETAILS_PATH = '/user-details';
export const EDIT_USER_DETAILS_PATH = `${USER_DETAILS_PATH}/edit`;

// TODO: dash-case all these routes

export interface AppointmentPathParams {
  appointmentId: string;
}
export const APPOINTMENT_PATH = '/schedule/:appointmentId';
export const CANCEL_APPOINTMENT_PATH = '/schedule/:appointmentId/cancel';
export const END_SESSION_EARLY_PATH = '/schedule/:appointmentId/endEarly';
export const RESCHEDULE_PATH = '/schedule/:appointmentId/reschedule';
export const RESCHEDULE_SUCCESS_PATH =
  '/schedule/:appointmentId/reschedule/success';
export const CARE_PLAN_PATH = `/patient/:patientId/care-plan`;

/** Payment **/
export interface PatientPaymentPathParams {
  appointmentId: string;
}
export const PATIENT_PAYMENT_PATH = '/payment/:appointmentId';
export interface PaymentSuccessPathParams {
  appointmentId: string;
}
export const PAYMENT_SUCCESS_PATH = '/paymentSuccess/:appointmentId';
export const CREDIT_CARD_INPUT_PATH = '/payment/:appointmentId/newCard';

export const ROOT_REDIRECT_PATH = SCHEDULE_PATH;
export const DEFAULT_LOGIN_PATH = SCHEDULE_PATH;

export interface PostSessionPathParams {
  appointmentId: string;
}
export const POST_SESSION_PATH = '/post-session/:appointmentId';
export const DEV_PATH = '/dev';

export interface AddressUpdatePathParams {
  patientId: string;
}
export const ADDRESS_UPDATE_PATH = '/address-update/:patientId';
export interface AddressInputPathParams {
  patientId: string;
}
export const ADDRESS_INPUT_PATH = '/address-input/:patientId';

export interface AppointmentReschedulePathParams {
  appointmentId: string;
}

export const PATIENT_REFERRAL_PATH = '/patient-referral';
export const PATIENT_REFERRAL_SUCCESS_PATH = '/patient-referral/success';

export const COMMUNICATION_PATH = '/communication';
export const OFFERS_PATH = `${COMMUNICATION_PATH}/offers`;
export const OFFER_PATH = `${OFFERS_PATH}/:offerId`;
export const CALENDAR_IMPORT_PATH = '/calendar-import';
