import { Epic } from 'redux-observable';
import { Action } from 'redux';
import { GlobalState } from 'reducers';
import { EpicDependencies } from 'store/middleware';
import { AppFileType } from 'utils/file/constants';
import { BookAppointmentSuccessActionPayload } from 'modules/appointments/actions';
import React from 'react';

export interface PayloadAction<PayloadType> extends Action<string> {
  payload: PayloadType;
}
export type CustomEpic<PayloadType> = Epic<
  PayloadAction<PayloadType>,
  any,
  GlobalState,
  EpicDependencies
>;
export interface ErrorActionPayload {
  name: string;
  message: string;
  status?: number; // ajax xhr status
}
export type ErrorAction = PayloadAction<ErrorActionPayload>;

export type CssTypeFix = { css?: any };

export enum CallResult {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Error = 'ERROR',
}
export interface TranslatedCall {
  result: CallResult;
  payload?: Object;
}

export enum Role {
  Ops = 'Ops',
  PT = 'PT',
  Unknown = '_unknown_',
}

export enum AppointmentType {
  Ongoing = 'Ongoing',
  Assessment = 'Assessment',
  Personal = 'Personal',
}

export enum AppointmentStatus {
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  TreatClient = 'Treat Client',
  Finished = 'Finished',
  Paid = 'Paid',
}

export enum AppointmentService {
  BcCignaHcpTreatment = 'BC / Cigna / HCP Treatment',
  BcCignaEval = 'BC / Cigna Eval',
  BcCignaTreatment = 'BC / Cigna Treatment',
  BcTeletherapy = 'BC Teletherapy',
  Blocked = 'Blocked',
  CancelledWithNotice = 'Cancelled with notice',
  CancelledWithoutNotice1st = 'Cancelled without notice 1st',
  CancelledWithoutNoticeMultiple = 'Cancelled without notice 2+',
  CashEval = 'Cash Eval',
  CashTeletherapy = 'Cash Teletherapy',
  CashTreatment = 'Cash Treatment',
  DischargeEval = 'Discharge Eval',
  EvalOnly = 'Eval Only',
  DoctorLunch = 'Doctor Lunch',
  HqMeeting = 'HQ Meeting',
  MedicareEVisit = 'Midicare E-Visit',
  MedicareEval = 'Medicare Eval',
  MedicareTeletherapyEval = 'Medicare Teletherapy Eval',
  MedicareTeletherapy = 'Medicare Teletherapy',
  MedicareTreatment = 'Medicare Treatment',
  OutOfOffice = 'Out of Office',
  PTO = 'PTO',
  PatientDoctorProgressVisit = 'Patient / Doctor Progress Visit',
  Personal = 'Personal',
  PrivateEval = 'Private Eval',
  PrivateTeletherapy = 'Private Teletherapy',
  PrivateTreatment = 'Private Treatment',
  Shadow = 'Shadow',
  TherapistMarketing = 'Therapist Marketing',
}

export interface AppointmentServiceType {
  id: string;
  name: AppointmentService;
  groupName: string;
  duration: number;
  status: string;
  points: string;
  insuranceType: string; // matches with insurance.insType
}

export enum PatientStatus {
  Active = 'Active',
  Deceased = 'Deceased',
  Inactive = 'Inactive',
}
export enum Gender {
  Male = 'M',
  Female = 'F',
}
export interface Patient {
  // zip?: string;
  accessNotes?: string;
  address?: string;
  cardBrand?: string; // "Visa"
  // This will be set by the API, for cards entered directly through the stripe backend. Can be used to confirm payments
  cardId?: string;
  cardLast4?: string; // of a number
  city?: string;
  dateOfBirth?: string;
  email?: string;
  firstName?: string;
  fullName: string;
  gender: Gender;
  id: number;
  insurance: string | null;
  lastName?: string;
  lat?: number;
  lng?: number;
  middleName: string | null;
  // This will be set by the app when users enter their card info, can be used to confirm payments
  paymentMethodId?: string;
  phone2: string | null;
  phone: string | null;
  reminder: string;
  state?: string;
  status: PatientStatus;
  zipCode: number;
}

export enum AppointmentStatusName {
  Arrived = 'Arrived',
  CancelledByPT = 'Cancelled by PT',
  CancelledFeeWaived = 'Cancelled fee waived',
  CancelledWithNotice = 'Cancelled with notice',
  CancelledWithoutNotice = 'Cancelled without notice',
  CancelledWithoutNotice1st = 'Cancelled without notice 1st',
  CancelledMoreWithoutNotice = 'Cancelled without notice 2+',
  CashTreatmentScheduled = 'Cash Treatment Scheduled',
  Finalized = 'Finalized',
  Finished = 'Finished',
  FlatRateEvalScheduled = 'Flat Rate Eval Scheduled',
  FlatRateTeletherapy = 'Flat Rate Teletherapy',
  FlatRateTreatmentScheduled = 'Flat Rate Treatment Scheduled',
  MedicareEvalScheduled = 'Medicare Eval Scheduled',
  MedicareTeletherapy = 'Medicare Teletherapy',
  MedicareTreatmentScheduled = 'Medicare Treatment Scheduled',
  NoShow = 'No Show',
  OnHold = 'On Hold',
  PrivateEvalScheduled = 'Private Eval Scheduled',
  PrivateTeletherapy = 'Private Teletherapy',
  PrivateTreatmentScheduled = 'Private Treatment Scheduled',
  ReScheduled = 'Re-Scheduled',
  Scheduled = 'Scheduled',
}

export interface Appointment {
  allowedAmount: string; // of a float
  amountPaid: string; // of a float
  appCount: number;
  appDatetime: string; // "2020-07-07T07:15:00.000Z"
  appFee: number | null; // fee for normal appointment
  appStatus: AppointmentStatusName;
  appType: AppointmentType;
  arrivedAt: string; // datetime
  cancellationFee: number | null; // fee to cancel without notice
  cancellationReason: string | null;
  caseId: number;
  duration: number;
  id: number;
  patientId: number;
  patientResponsibility: string; // of a float
  service: AppointmentService;
  status: AppointmentStatus | null;
  // stripe payment intent client_secret key https://stripe.com/docs/api/payment_intents/object#payment_intent_object-client_secret
  // null if no money owed
  // created when the appointment status is set to 'Arrived'
  stripePiSecret: string | null;
  techFee: string; // of a float
  therapistId: number;
  visitNumber: number;
}

export interface Insurance {
  id: number;
  name: string;
  insType: string;
  // address: string;
  // city: string;
  // state: string;
  // zip: string;
  // inNetwork: string;
}

export type BookedAppointment = BookAppointmentSuccessActionPayload;

export interface TherapyGoal {
  name: string;
  dueDate: string; // "2020-09-22"
  desired: string; // desired goal
  progressDate: string; // "2020-09-22"
  progress: string; // current progress
}

export enum CaseStatus {
  Active = 'Active',
  OnHold = 'On Hold',
  Discharged = 'Discharged',
}
export interface Proc {
  name: string;
  minutes: string; // of a number
  note: string;
}
export interface Case {
  additionalVisitsType: string;
  // ccFormReceived: boolean;
  city: string;
  // cxlVisits: number;
  // dedVisitResponsibility: string; // of a number
  description: string;
  doctorId: number;
  endDate: string | null;
  evalDate: string; // "2020-03-16"
  // evalResponsibility: string; // of a float
  // evalTherapistId: number;
  // formsReceived: boolean;
  // goals: TherapyGoal[];
  // hardCancellations: number;
  // holdReason: null;
  // holdUntil: null;
  id: number;
  // ins1Id: number;
  // ins2Id: number;
  insAvailableVisits: number;
  lastDrVisit: string | null;
  // lastOpsToTherapist: null;
  // lastTherapistToOps: null;
  lastVisitAppCount: number;
  lastVisitAt: string; // "2020-05-07T13:15:00.000Z"
  leadId: number;
  // leadSource: string;
  // managedRegion: string;
  // needSchedulingAction: boolean;
  name: string;
  nextVisitAt: string | null; // "2020-05-07T13:15:00.000Z"
  // noshowVisits: number;
  // onHold: boolean;
  patientId: number;
  payers: string; // "BS CA"
  // perVisitFee: string; // of a float
  pocDate: null; // 2020-05-07
  // prescribedFrequency: string; // of a number
  // prescribedVisits: string; // of a number
  procs: Proc[];
  // responsibilityType: string;
  // rxCallDate: string; // "2020-03-16"
  // rxReceived: boolean;
  scheduledVisitCount: number;
  // schedulingPriority: number;
  // service: null; // unknown
  // softCancellations: number;
  startDate: string; // "2020-03-16"
  status: CaseStatus;
  statusComment: string | null;
  totalForecastVisits: number;
  totalPrescribedVisits: string; // of a number
  treatingTherapistId: number;
  // visitFrequency: string;  // of a float
  zip: string;
}

export enum EmploymentType {
  FullTime = 'Full-Time',
  PartTime = 'Part-Time',
  None = 'None',
}
export enum LicenseType {
  Therapist = 'PT',
  TherapistAssistant = 'PTA',
  None = 'None',
}
export enum Status {
  Active = 'Active',
  Inactive = 'Inactive',
  None = 'None',
}
export interface Therapist {
  active: number | null;
  address: string;
  cell: string | null;
  city: string;
  clId: string;
  cxlRate: number | null; // cancellation rate
  companyEmail?: string;
  email: string;
  employmentType: EmploymentType;
  fillRate: number | null;
  firstName: string;
  freq: number | null;
  id: string;
  lastName: string;
  license: LicenseType;
  notes?: string;
  onHold: boolean | null;
  phone: string;
  ptPoints: number | null;
  ptPta: string | null; // "PT"
  state: string;
  status: Status;
  targetVisitsPerMonth: number;
  thisMonthsVisits: number | null;
  thisWeeksVisits: number | null;
  thisWeeksGoal: number;
  visits: number | null;
  zip: string;
}

export enum FetchStatus {
  None = 'None',
  Fetching = 'Fetching',
  Success = 'Success',
  Failure = 'Failure',
  Unauthorized = 'Unauthorized',
}

export enum DoctorStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}
export interface Doctor {
  id: number;
  prefix: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  emrKey: string;
  phone: string | null;
  cell: string | null;
  email: string | null;
  status: DoctorStatus;
  specialty: string | null;
  licenseNumber: string;
}

export interface Lead {
  // backFromVerificationAt: string,
  // callbackNumber: string,
  // callerFirstName: string,
  // callerLastName: string,
  // caseId: number,
  // collectCcFormAtEval: boolean,
  // collectCopiesOfInsCa: boolean,
  // collectFormsAtEval: boolean,
  // createdAt: string,
  // dedAppliesForVisits: number,
  // deductibleStatus: null,
  // dedVisitResponsibility: string,
  // doctorId: number,
  // evalResponsibility: string,
  // evalScheduledAt: string,
  // evalSpecialInstructions: string,
  // evalTherapistId: number,
  // formsReceived: boolean,
  // formsToSend: string,
  futureDrVisit: string;
  id: number;
  injuryBodyArea: string;
  // ins1Id: number,
  // ins2Id: number,
  // insAvailableVisits: number,
  // lastActivityComment: string,
  // lastActivityDate: string,
  // lastCoveredVisit: number,
  // lastDrVisit: null,
  // lastNoteToOps: null,
  lastNoteToTherapist: string | null;
  // lastVisit: null,
  // leadCxlReason: null,
  // leadCxlReasonDesc: null,
  // leadSource: string,
  // leadSourceDescription: string,
  // newTherapistNoteToOps: null,
  // noteToOps: null,
  // ongoingSpecialInstructi: null,
  patientFirstName: string;
  patientId: number;
  patientLastName: string;
  // patientPhone2: null,
  // patientPhone: string,
  // patientResponsibility: string,
  // patientStatus: null,
  // perVisitFee: string,
  // prescribedFrequency: string,
  // prescribedVisits: string,
  // prescribingDoctorId: number,
  // ptInsResponsibility: string,
  // readyToScheduleAt: null,
  // rxCallDate: string,
  // rxReceived: boolean,
  // rxReceivedAt: string,
  // softConvertAt: string,
  // status: string,
  // totalPrescribedVisits: string,
  // totalVisitForecast: number,
  // treatingTherapistId: null,
  treatmentAddressNotes: string; // may contain home and parking info
  // trmtResponsibility: string,
  // verificationId: number,
  // zip: string,
  // zipCode: number,
  missingDocs: Array<string>;
}

// App-defined offer type, used to enforce types through discriminated union
export enum OfferType {
  ListOffer = 'ListOffer', // obtained through /offers endpoint
  DetailedOffer = 'DetailedOffer', // obtained through /offer/:offerId endpoint
  FullOffer = 'FullOffer', // the the same offer is fetched with both
}

export enum OfferStatus {
  Ready = 'Ready',
  Claimed = 'Claimed',
  Unclaimed = 'Unclaimed',
  Offered = 'Offered',
  Scheduled = 'Scheduled',
  PatientCancelled = 'PatientCancelled',
}

// Item returned by the offers endpoint
export interface ListOffer {
  id: number;
  leadId: number;
  status: OfferStatus;
  city: string;
  region: string;
  createdAt: string; // "2021-08-12T08:15:23.000Z"
  patientName: string; // first and last name
  treatment: string;
  claimedAt: string | null;
  assignedTherapist: string | null;
  unclaimedAt: string | null;
  updatedAt: string | null;
  postedAt: string;
  acceptedAt: string | null;
}

// Item returned by offer details endpoint
export interface DetailedOffer {
  id: number;
  leadId: number;
  status: OfferStatus;
  city: string;
  region: string;
  patientFirstName: string;
  patientLastName: string;
  insurance: string;
  bodyParts: string;
  address: string;
  apt: string; // 'suit_apt' in API
  zipCode: string;
  homePhone: string | null;
  mobilePhone: string | null;
  notificationCall: boolean | null;
  notificationText: boolean | null;
  notes: string | null;
  basedOnInsurance: number;
  availableStartDate: string; // "2021-10-19"
  asap: boolean;
  offerRegions: string;
  offerTherapists: string | null; // unknown if this is actually string
}

export enum CreditCardType {
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  AmericanExpress = 'AmericanExpress',
  Discover = 'Discover',
}

export interface CreditCard {
  id: string;
  type: CreditCardType;
  lastFourDigits: string;
}

export enum CardBrand {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  Unionpay = 'UNIONPAY',
  Visa = 'VISA',
  Unknown = 'UNKNOWN',
}

export enum Feature {
  Payment = 'PAYMENT',
  PaymentCardInput = 'PAYMENT_CARD_INPUT',
  PatientReferral = 'PATIENT_REFERRAL',
  OfferModel = 'OFFER_MODEL',
  FileUploadHook = 'FILE_UPLOAD_HOOK',
  CompositeImageUpload = 'COMPOSITE_IMAGE_UPLOAD',
  IntakeFormRequest = 'INTAKE_FORM_REQUEST',
  MissingDocumentsNotices = 'MISSING_DOCUMENTS_NOTICES',
  HomeHealthAlert = 'HOME_HEALTH_ALERT',
  NewEndSessionEndpoint = 'NEW_END_SESSION_ENDPOINT',
  OnHoldScheduleFilter = 'ON_HOLD_SCHEDULE_FILTER',
  DeveloperLogin = 'DEVELOPER_LOGIN',
  FutureAppointmentsOnly = 'FUTURE_APPOINTMENTS_ONLY',
  CalendarImport = 'CALENDAR_IMPORT',
}

export enum Cohort {
  None = 'NONE',
  Developers = 'Developers',
  Insiders = 'INSIDERS',
  FullTime = 'FULL_TIME',
}

export interface NavItemProps {
  icon: React.ReactNode;
  label: React.ReactNode;
  route: string;
  isSelected?: boolean;
  isDisabled?: boolean;
}

export interface AppFile {
  fileName: string;
  fileId: string;
  fileUrl?: string;
  file?: string; // file in base64
  uploadType: AppFileType;
}
