import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import {
  AppointmentServiceType,
  Case,
  FetchStatus,
  Patient,
} from 'types/types';
import { Moment } from 'moment';
import { Location } from 'history';
import * as Yup from 'yup';

import { bookAppointment as bookAppointmentAction } from 'modules/appointments/actions';
import { GlobalState } from 'reducers';
import { getScheduleFetchStatus } from 'selectors/schedule/getScheduleFetchStatus';
import { useFetchStatusHooks } from 'utils/hooks/useFetchStatusHooks';
import { BookingSuccessPage } from 'pages/booking/BookingSuccessPage';
import { getAppointmentServiceTypes } from 'selectors/appointmentServiceTypes/getAppointmentServiceTypes';
import { parse } from 'query-string';
import { fetchCase as fetchCaseAction } from 'modules/cases/actions';
import { getCase } from 'selectors/cases/getCase';
import { getPatient } from 'selectors/patients/getPatient';
import { fetchPatient } from 'modules/patients/actions';
import { BookingRouter } from 'pages/booking/BookingRouter';
import { debounce } from 'lodash';

export interface UrlSearchParams {
  caseId?: string;
}

interface OwnProps {
  location: Location;
}

interface StateProps {
  appointmentServiceTypes: Record<string, AppointmentServiceType>;
  scheduleFetchStatus: FetchStatus;
  existingCase?: Case;
  existingPatient?: Patient | undefined;
}

interface DispatchProps {
  bookAppointment: typeof bookAppointmentAction;
  fetchCase: typeof fetchCaseAction;
}

type Props = StateProps & DispatchProps & OwnProps;

export interface FormValues {
  appointmentServiceType?: AppointmentServiceType;
  patientName?: string;
  appointmentDate?: Moment;
  appointmentTime?: Moment;
  patient?: Patient;
  caseId?: number;
}

const getCaseIdFromLocation = (location: Location) =>
  (parse(location.search) as UrlSearchParams)?.caseId;

const defaultInitialValues: FormValues = {
  /* Values for development: */
  // appointmentServiceType,
  // patient: {
  //   "id": 1769,
  //   "firstName": "Brian",
  //   "lastName": "Miller",
  //   "zip": "90069",
  //   "insurance1": "BS CA",
  //   "phone": "3102373435",
  //   "email": "heybrianla@gmail.com",
  //   caseId: 2132,
  // },
  // patientName: 'Miller, Brian',
  // appointmentDate: moment(),
  // appointmentTime: moment().add(1, 'hour').startOf('hour'),
};

const validationSchema = Yup.object().shape({
  appointmentServiceType: Yup.mixed().required(),
  patient: Yup.mixed().required(),
  appointmentDate: Yup.mixed().required(),
  appointmentTime: Yup.mixed().required(),
});

const mapStateToProps = (
  state: GlobalState,
  ownProps: OwnProps
): StateProps => {
  const existingCase = getCase(state, {
    caseId: getCaseIdFromLocation(ownProps.location) || '',
  });
  const existingPatient = existingCase
    ? getPatient(state, { patientId: existingCase.patientId })
    : undefined;

  return {
    appointmentServiceTypes: getAppointmentServiceTypes(state),
    scheduleFetchStatus: getScheduleFetchStatus(state),
    existingCase,
    existingPatient,
  };
};

const mapDispatchToProps = {
  bookAppointment: bookAppointmentAction,
  fetchCase: fetchCaseAction,
};

const BookingComponent = ({
  location,
  bookAppointment,
  scheduleFetchStatus,
  fetchCase,
  existingCase,
  existingPatient,
}: Props) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { armHook } = useFetchStatusHooks({
    fetchStatus: scheduleFetchStatus,
    onSuccess: useCallback(() => {
      setIsSuccess(true);
    }, [setIsSuccess]),
  });

  const caseIdFromUrl = getCaseIdFromLocation(location);

  // Fetch case if necessary
  useEffect(() => {
    if (caseIdFromUrl && !existingCase) {
      fetchCase({ caseId: caseIdFromUrl });
    }
  }, [caseIdFromUrl, fetchCase, existingCase]);

  // Fetch patient if necessary
  useEffect(() => {
    if (caseIdFromUrl && !existingPatient && existingCase?.patientId) {
      fetchPatient({ patientId: String(existingCase?.patientId) });
    }
  }, [existingPatient, existingCase, caseIdFromUrl]);

  const submitBooking = (values: FormValues) => {
    const {
      appointmentServiceType,
      appointmentDate,
      appointmentTime,
      patient,
      caseId,
    } = values as Required<FormValues>;
    const resultDateTime = appointmentDate
      .clone()
      .hour(appointmentTime.hour())
      .minute(appointmentTime.minute());

    bookAppointment({
      dateTime: resultDateTime.format(),
      type: appointmentServiceType.name,
      patientId: patient.id || 0,
      duration: appointmentServiceType.duration,
      caseId,
    });
    armHook();
  };

  const debouncedSubmitBooking = debounce(submitBooking, 500);

  if (isSuccess) {
    return <BookingSuccessPage />;
  }

  const initialValues = {
    ...defaultInitialValues,
  };

  if (existingPatient) {
    initialValues.patient = existingPatient;
    initialValues.patientName = `${existingPatient.lastName}, ${existingPatient.firstName}`;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={debouncedSubmitBooking}
      validationSchema={validationSchema}
    >
      {BookingRouter}
    </Formik>
  );
};

export const Booking = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingComponent);
