import { CustomEpic } from 'types/types';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { FETCH_CALENDAR_LINK } from 'modules/calendar/actionTypes';
import { getFetchCalendarUrl } from 'modules/calendar/utils/getFetchCalendarUrl';
import { getId } from 'selectors/user/getId';
import {
  fetchCalendarLinkFailure,
  fetchCalendarLinkSuccess,
} from 'modules/calendar/actions';
import { convertSnakeToCamelCase } from 'utils/convertSnakeToCamelCase';

export const fetchCalendarLink$: CustomEpic<{}> = (
  action$,
  state$,
  { getApi$ }
) =>
  action$.pipe(
    ofType(FETCH_CALENDAR_LINK),
    switchMap(() =>
      getApi$({
        endpoint: getFetchCalendarUrl({
          therapistId: getId(state$.value),
        }),
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(fetchCalendarLinkSuccess, convertSnakeToCamelCase),
        handleAjaxError(fetchCalendarLinkFailure)
      )
    )
  );
