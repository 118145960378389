import { Action, CombinedState, combineReducers } from 'redux';
import auth, { AuthState } from './auth';
import user, { UserState } from './user';
import schedule, { ScheduleState } from './schedule';
import patients, { PatientsState } from './patients';
import appointmentServiceTypes, {
  AppointmentServiceTypesState,
} from './appointmentServiceTypes';
import cases, { CasesState } from './cases';
import appointments, { AppointmentState } from 'reducers/appointments';
import doctors, { DoctorState } from 'reducers/doctors';
import insurances, { InsuranceState } from 'reducers/insurances';
import leads, { LeadsState } from 'reducers/leads';
import { LOGOUT } from 'modules/auth/actionTypes';
import payment, { PaymentState } from 'reducers/payment';
import features, { FeaturesState } from 'reducers/features';
import debug, { DebugState } from 'reducers/debug';
import api, { ApiState } from 'reducers/api';
import offers, { OffersState } from 'reducers/offers';
import evalSession, { EvalSessionState } from 'reducers/evalSession';
import files, { FilesState } from './files';
import caseAppTypes, { CasesAppointmentTypesState } from './caseAppTypes';
import calendar, { CalendarState } from 'reducers/calendar';

export interface GlobalState {
  api: ApiState;
  appointments: AppointmentState;
  appointmentServiceTypes: AppointmentServiceTypesState;
  auth: AuthState;
  calendar: CalendarState;
  cases: CasesState;
  debug: DebugState;
  doctors: DoctorState;
  features: FeaturesState;
  files: FilesState;
  insurances: InsuranceState;
  leads: LeadsState;
  offers: OffersState;
  patients: PatientsState;
  payment: PaymentState;
  schedule: ScheduleState;
  user: UserState;
  evalSession: EvalSessionState;
  caseAppTypes: CasesAppointmentTypesState;
}

export const appReducers = combineReducers<GlobalState>({
  api,
  appointments,
  appointmentServiceTypes,
  auth,
  calendar,
  cases,
  debug,
  doctors,
  features,
  files,
  leads,
  offers,
  insurances,
  patients,
  payment,
  schedule,
  user,
  evalSession,
  caseAppTypes,
});

export const reducers = (
  state?: CombinedState<GlobalState>,
  action: Action = { type: '' }
) => {
  if (action.type === LOGOUT) {
    return appReducers(undefined, action);
  }

  return appReducers(state, action);
};
