import React, { useCallback, useEffect } from 'react';
import { Button, Flex, Text } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { CloseIcon } from 'components/icons/CloseIcon';
import { useGoBack } from 'utils/hooks/useGoBack';
import { useDispatchFunction } from 'utils/hooks/useDispatchFunction';
import { fetchCalendarLink } from 'modules/calendar/actions';
import { useSelector } from 'react-redux';
import { getCalendarFetchStatus } from 'selectors/calendar/getCalendarFetchStatus';
import { getCalendarUrl } from 'selectors/calendar/getCalendarUrl';
import { isFetching } from 'utils/fetchStatus';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import toast from 'react-hot-toast';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';

const GOOGLE_CALENDAR_INSTRUCTIONS_URL =
  'https://support.google.com/calendar/answer/37100?co=GENIE.Platform%3DDesktop&oco=1';
const APPLE_CALENDAR_INSTRUCTIONS_URL =
  'https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac';
const OUTLOOK_CALENDAR_INSTRUCTIONS_URL =
  'https://support.microsoft.com/en-us/office/import-calendars-into-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379';

export const CalendarImportPage = () => {
  const goBack = useGoBack();

  const fetchCalendarUrl = useDispatchFunction(fetchCalendarLink);

  useEffect(() => fetchCalendarUrl(), [fetchCalendarUrl]);
  const calendarUrl = useSelector(getCalendarUrl);
  const fetchStatus = useSelector(getCalendarFetchStatus);

  const copyText = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(calendarUrl);
      toast.success('Text copied');
    } catch (err) {
      toast.error(`Unable to copy text: ${err}`);
    }
  }, [calendarUrl]);

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Import Calendar"
        right={<CloseIcon size={20} onClick={goBack} />}
      />
      <Flex
        width="100%"
        height="100%"
        p={3}
        flexDirection="column"
        alignItems="center"
      >
        Use this web address to import all your current and future appointments
        into your calendar of choice.
        <Flex
          m={2}
          marginTop={3}
          p={2}
          backgroundColor="muted"
          justifyContent="center"
          style={{
            borderRadius: '4px',
            fontSize: 'smaller',
          }}
        >
          {isFetching(fetchStatus) ? <LoadingSpinner size={20} /> : calendarUrl}
        </Flex>
        <Button width="120px" marginTop={2} onClick={copyText}>
          Copy URL
        </Button>
        <Flex marginTop={3} flexDirection="column">
          <Text>How to import your calendar in:</Text>
          <ul>
            <li>
              <CustomTextLink href={GOOGLE_CALENDAR_INSTRUCTIONS_URL}>
                Google Calendar
              </CustomTextLink>
              Under "Use a link to add a public calendar"
            </li>
            <li style={{ marginTop: '10px' }}>
              <CustomTextLink href={APPLE_CALENDAR_INSTRUCTIONS_URL}>
                Apple Calendar
              </CustomTextLink>
            </li>
            <li style={{ marginTop: '10px' }}>
              <CustomTextLink href={OUTLOOK_CALENDAR_INSTRUCTIONS_URL}>
                Microsoft Outlook
              </CustomTextLink>
            </li>
          </ul>
        </Flex>
        <Flex width="100%" flexDirection="column" justifyContent="flex-start">
          <Text fontWeight="bold" marginBottom={1}>
            Note:
          </Text>
          This imports your calendar from a URL. Calendars created this way will
          be automatically updated when any changes are made by PeteHealth. This
          includes rescheduled and canceled meetings.
        </Flex>
      </Flex>
    </Flex>
  );
};
