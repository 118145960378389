import { handleActions } from 'redux-actions';
import {
  FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS,
  LOGIN_SUCCESS,
} from 'modules/auth/actionTypes';
import {
  FetchGoogleOauth2TokensSuccessPayload,
  LoginSuccessPayload,
} from 'modules/auth/actions';
import {
  FETCH_THERAPIST,
  FETCH_THERAPIST_FAIL,
  FETCH_THERAPIST_SUCCESS,
  UPDATE_THERAPIST,
  UPDATE_THERAPIST_FAIL,
  UPDATE_THERAPIST_SUCCESS,
} from 'modules/therapist/actionTypes';
import {
  FetchTherapistSuccessActionPayload,
  UpdateTherapistSuccessActionPayload,
} from 'modules/therapist/actions';
import { combineReducers } from 'redux';
import {
  EmploymentType,
  ErrorActionPayload,
  FetchStatus,
  LicenseType,
  Status,
  Therapist,
} from 'types/types';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';

export interface UserState {
  user: Therapist;
  fetchStatus: FetchStatus;
  failureMessage: string | null;
}

const initialTherapist: Therapist = {
  active: null,
  address: '',
  cell: null,
  city: '',
  clId: '',
  cxlRate: null,
  email: '',
  employmentType: EmploymentType.None,
  fillRate: null,
  firstName: '',
  freq: null,
  id: '',
  lastName: '',
  license: LicenseType.None,
  notes: '',
  onHold: null,
  phone: '',
  ptPoints: null,
  ptPta: '',
  state: '',
  status: Status.None,
  targetVisitsPerMonth: 0,
  thisMonthsVisits: null,
  thisWeeksVisits: null,
  thisWeeksGoal: 0,
  visits: null,
  zip: '',
};

const user = handleActions<Therapist, any>(
  {
    [LOGIN_SUCCESS]: (
      state,
      {
        payload: {
          user: { id, firstName, lastName },
        },
      }: { payload: LoginSuccessPayload }
    ) => ({
      ...state,
      id: String(id),
      firstName,
      lastName,
    }),
    [FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS]: (
      state,
      {
        payload: {
          user: { id, first_name, last_name },
        },
      }: { payload: FetchGoogleOauth2TokensSuccessPayload }
    ) => ({
      ...state,
      id: String(id),
      firstName: first_name,
      lastName: last_name,
    }),
    [FETCH_THERAPIST_SUCCESS]: (
      state,
      { payload }: { payload: FetchTherapistSuccessActionPayload }
    ) => ({
      ...state,
      ...payload,
    }),
    [UPDATE_THERAPIST_SUCCESS]: (
      state,
      { payload }: { payload: UpdateTherapistSuccessActionPayload }
    ) => ({
      ...state,
      ...payload,
    }),
  },
  initialTherapist
);

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [FETCH_THERAPIST, UPDATE_THERAPIST],
  successActions: [FETCH_THERAPIST_SUCCESS, UPDATE_THERAPIST_SUCCESS],
  failureActions: [FETCH_THERAPIST_FAIL, UPDATE_THERAPIST_FAIL],
});

const resetFailureMessage = () => null;
const setFailureMessage = (
  state: UserState['failureMessage'],
  { payload }: { payload: ErrorActionPayload }
) => payload.message;
const failureMessage = handleActions<string | null, any>(
  {
    [FETCH_THERAPIST]: resetFailureMessage,
    [FETCH_THERAPIST_SUCCESS]: resetFailureMessage,
    [UPDATE_THERAPIST]: resetFailureMessage,
    [UPDATE_THERAPIST_SUCCESS]: resetFailureMessage,
    [FETCH_THERAPIST_FAIL]: setFailureMessage,
    [UPDATE_THERAPIST_FAIL]: setFailureMessage,
  },
  null
);

export default combineReducers<UserState>({
  user,
  fetchStatus,
  failureMessage,
});
