import { Cohort, Feature } from 'types/types';

export interface FeatureState {
  isGloballyEnabled: boolean;
  whitelist?: string[];
  blacklist?: string[];
  cohort?: Cohort;
}

export type FeaturesState = Record<Feature, FeatureState>;

// TODO clean up unnecessary feature gates
const state: FeaturesState = {
  [Feature.Payment]: {
    isGloballyEnabled: false,
    cohort: Cohort.None,
  },
  [Feature.PaymentCardInput]: {
    isGloballyEnabled: true,
  },
  [Feature.PatientReferral]: {
    isGloballyEnabled: true,
    cohort: Cohort.FullTime,
  },
  [Feature.OfferModel]: {
    isGloballyEnabled: false,
    cohort: Cohort.Developers,
  },
  [Feature.FileUploadHook]: {
    isGloballyEnabled: true,
    cohort: Cohort.Insiders,
  },
  [Feature.CompositeImageUpload]: {
    isGloballyEnabled: false,
    cohort: Cohort.Insiders,
  },
  [Feature.IntakeFormRequest]: {
    isGloballyEnabled: true,
  },
  [Feature.MissingDocumentsNotices]: {
    isGloballyEnabled: true,
  },
  [Feature.HomeHealthAlert]: {
    isGloballyEnabled: true,
  },
  [Feature.NewEndSessionEndpoint]: {
    isGloballyEnabled: true,
  },
  [Feature.OnHoldScheduleFilter]: {
    isGloballyEnabled: true,
  },
  [Feature.DeveloperLogin]: {
    isGloballyEnabled: false,
    cohort: Cohort.Developers,
  },
  [Feature.FutureAppointmentsOnly]: {
    isGloballyEnabled: false,
    cohort: Cohort.Insiders,
  },
  [Feature.CalendarImport]: {
    isGloballyEnabled: false,
    cohort: Cohort.Insiders,
  },
};

// Keeping as reducer to facilitate a change to remotely controlled
//   feature gates later
export default () => state;
