import { combineEpics } from 'redux-observable';
import { epics as authEpics } from 'modules/auth';
import { epics as appointmentsEpics } from 'modules/appointments';
import { epics as scheduleEpics } from 'modules/schedule';
import { epics as casesEpics } from 'modules/cases';
import { epics as appointmentServiceTypesEpics } from 'modules/appointmentServiceTypes';
import { epics as therapistEpics } from 'modules/therapist';
import { epics as leadEpics } from 'modules/leads';
import { epics as patientEpics } from 'modules/patients';
import { epics as insuranceEpics } from 'modules/insurance';
import { epics as paymentEpics } from 'modules/payment';
import { epics as patientReferralEpics } from 'modules/patientReferral';
import { epics as offerEpics } from 'modules/offers';
import { epics as hookEpics } from 'modules/hooks';
import { epics as fileEpics } from 'modules/files';
import { epics as calendarEpics } from 'modules/calendar';

// consider adding global error handler https://redux-observable.js.org/docs/basics/SettingUpTheMiddleware.html
export default combineEpics(
  appointmentsEpics,
  appointmentServiceTypesEpics,
  authEpics,
  casesEpics,
  leadEpics,
  scheduleEpics,
  therapistEpics,
  patientEpics,
  insuranceEpics,
  paymentEpics,
  patientReferralEpics,
  offerEpics,
  hookEpics,
  fileEpics,
  calendarEpics
);
