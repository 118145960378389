import React from 'react';
import { PaymentMethod } from 'pages/payment/types';
import { CardBrand, OfferStatus } from 'types/types';
import {
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
  FaCreditCard,
} from 'react-icons/all';
import { IconType } from 'react-icons';
import {
  DARK_BLUE,
  GREY,
  LIGHT_ORANGE,
  LIGHT_PINK,
  LIGHTER_ORANGE,
} from 'theme/colors';
import { HeaderStyle } from 'components/OfferListSection/constants';

export const daysOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

// Not currently used, but kept around in case we need it again
export const sampleText =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco';

export const dateFormat = 'YYYY-MM-DD';
export const timeFormat = 'HH:mm:ss';
export const appointmentDateTimeFormat = undefined; // ISO format

export const helpMessage = `Issue? Call us now! - ${process.env.REACT_APP_HELP_NUMBER}`;

export const paymentMethodString: Record<PaymentMethod, string> = {
  [PaymentMethod.CashOrCheck]: 'Cash / Check',
  [PaymentMethod.CreditOrDebit]: 'Credit / Debit',
};

export const cardIcons: Record<CardBrand, IconType> = {
  [CardBrand.Amex]: FaCcAmex,
  [CardBrand.Diners]: FaCcDinersClub,
  [CardBrand.Discover]: FaCcDiscover,
  [CardBrand.Jcb]: FaCcJcb,
  [CardBrand.Mastercard]: FaCcMastercard,
  [CardBrand.Unionpay]: FaCreditCard,
  [CardBrand.Visa]: FaCcVisa,
  [CardBrand.Unknown]: FaCreditCard,
};

// The cl_id value of the user
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TED_ID = '3';

export const INSIDERS = [TED_ID] as const;
export const INSIDERS_SET = new Set(INSIDERS);

export const PHONE_REGEX = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const formattedOfferStatus: Record<OfferStatus, React.ReactNode> = {
  [OfferStatus.Ready]: 'Ready',
  [OfferStatus.Claimed]: 'Claimed',
  [OfferStatus.Unclaimed]: 'Unclaimed',
  [OfferStatus.Offered]: 'Offered',
  [OfferStatus.Scheduled]: 'Scheduled',
  [OfferStatus.PatientCancelled]: 'Cancelled',
};

export const offerStatusColors: Record<OfferStatus, HeaderStyle> = {
  [OfferStatus.Ready]: {
    color: 'lightgreen',
    textColor: DARK_BLUE,
  },
  [OfferStatus.Claimed]: {
    color: LIGHT_ORANGE,
    textColor: DARK_BLUE,
  },
  [OfferStatus.Unclaimed]: {
    color: GREY,
    textColor: 'white',
  },
  [OfferStatus.Offered]: {
    color: LIGHT_PINK,
    textColor: DARK_BLUE,
  },
  [OfferStatus.Scheduled]: {
    color: DARK_BLUE,
    textColor: 'white',
  },
  [OfferStatus.PatientCancelled]: {
    color: 'black',
    textColor: 'white',
  },
};

interface MessageStyle {
  color: string;
  text: string;
}
export const offerStatusMessages: Record<OfferStatus, MessageStyle | null> = {
  [OfferStatus.Ready]: null,
  [OfferStatus.Claimed]: {
    color: LIGHTER_ORANGE,
    text:
      '*Appointment has been confirmed, this offer will be updated on your booking calendar once you book appointment. Please make sure you have confirmed the time with your patient. Text and email will be sent over once you booking is completed. Note that this offer detail will be deleted on the day of the appointment. If you need to cancel or change schedule please contact CS.',
  },
  [OfferStatus.Unclaimed]: {
    color: LIGHT_PINK,
    text:
      '* You have successfully rejected this offer, and it has now been made available to other therapists. If you have changed your mind please check back in 24 hours to see if this offer becomes available once more to you.',
  },
  [OfferStatus.Offered]: null,
  [OfferStatus.Scheduled]: {
    color: LIGHTER_ORANGE,
    text:
      '*Appointment has been confirmed, this offer will be updated on your booking calendar. Note that this offer detail will be deleted on the day of the appointment. If you need to cancel or change your schedule please contact CS.',
  },
  [OfferStatus.PatientCancelled]: {
    color: LIGHT_PINK,
    text:
      '* Appointment has been cancelled because client has decided not to proceed.',
  },
};
